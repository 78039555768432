import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum Network {
  BSC = 'BSC',
  BSC_TESTNET = 'BSC_TESTNET',
  FANTOM = 'FTM',
  FTM = 'FTM',
  FTM_TESTNET = 'FTM',
  METIS = 'METIS',
  METIS_TESTNET = "METIS_TESTNET"
}

export enum ChainId {
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  FTM_MAINNET = 250,
  FTM_TESTNET = 4002,
  METIS = 1088,
  METIS_TESTNET = 588
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

// TO DO: use proper types here
export const FACTORY_ADDRESS: Record<ChainId, string> = {
  [ChainId.BSC_MAINNET]: '0xaC653cE27E04C6ac565FD87F18128aD33ca03Ba2',
  [ChainId.BSC_TESTNET]: '0xaC653cE27E04C6ac565FD87F18128aD33ca03Ba2',
  [ChainId.FTM_MAINNET]: '0x991152411A7B5A14A8CF0cDDE8439435328070dF',
  [ChainId.FTM_TESTNET]: '0x991152411A7B5A14A8CF0cDDE8439435328070dF',
  [ChainId.METIS]: '0xAA1504c878B158906B78A471fD6bDbf328688aeB',
  [ChainId.METIS_TESTNET]: '0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73'
}
export const INIT_CODE_HASH: Record<ChainId, string> = {
  [ChainId.BSC_MAINNET]: '0x0b3961eeccfbf746d2d5c59ee3c8ae3a5dcf8dc9b0dfb6f89e1e8ca0b32b544b',
  [ChainId.BSC_TESTNET]: '0x0b3961eeccfbf746d2d5c59ee3c8ae3a5dcf8dc9b0dfb6f89e1e8ca0b32b544b',
  [ChainId.FTM_MAINNET]: '0xc7afa4a3485a5e46a8e6c01f3d79956707846c2145019cf4004d190147370afe',
  [ChainId.FTM_TESTNET]: '0xc7afa4a3485a5e46a8e6c01f3d79956707846c2145019cf4004d190147370afe',
  [ChainId.METIS]: '0x68b379a01bf77e2832a78c391e57da6365754d5b3339786b4f37f167edf6a6b7',
  [ChainId.METIS_TESTNET]: '0xdc79f03e37f0457d2b65d3b6a10d097b93d9661157e1e1cd98527e7525c62be8'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _996 = JSBI.BigInt(996)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
